@import '../../styles/_biocant-variables.scss';
@import '../../styles/mixins.scss';

// TEXT BOX
.text-box {
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .text-box-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }

  .text-box-input {
    width: 100%;
    color: $dark-grey;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 15px;
    border: 1px solid $white;
    background-color: $grey-light;
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color: $color-text-grayed-light;
    }
    &::placeholder {
      color: $color-text-grayed-light;
    }
  }

  .text-box-validation-icon {
    position: absolute;
    margin: 10px;
    right: 5px;
  }

  .text-box-message {
    font-size: 0.6875rem;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
  }
}

.full-width {
  width: 100%;
}

.text-box.error {
  .text-box-input {
    border-color: $color-error;
    color: $color-error;
  }

  .text-box-icon-wrapper {
    color: $color-error;
    border-color: $color-error;
  }

  .text-box-message {
    color: $color-error;
  }
}

// TEXT AREA
.text-area {
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .text-area-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-area-input {
    width: 100%;
    color: $dark-grey;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 15px;
    border: 1px solid $white;
    background-color: $grey-light;
    border-radius: 4px;
    outline: none;
    resize: none;
    -ms-overflow-style: none;
    overflow: auto;

    &:focus {
      border-color: $color-text-grayed-light;
      background-color: $grey-light;
      color: $dark-grey;
    }

    &::placeholder {
      color: $color-text-grayed-light;
    }
  }

  .text-area-message {
    font-size: 0.6875rem;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
  }
}

.text-area.error {
  .text-area-input {
    border-color: $color-error;
  }

  .text-area-message {
    color: $color-error;
  }
}

//PHONE NUMBER INPUT
.phone-input-field {
  position: relative;
  text-align: start;
  margin-bottom: -8px;
  .phone-input {
    --PhoneInput-color--focus: none;
    --PhoneInputCountrySelectArrow-color--focus: none;
    --PhoneInputCountryFlag-borderColor--focus: none;
    --PhoneInputCountryFlag-borderColor: none;
    --PhoneInputCountrySelectArrow-opacity: 1;
    --PhoneInputCountrySelectArrow-color: $color-text-grayed-light;
    width: 100%;
    color: $dark-grey;
    font-size: 16px;
    line-height: 18px;
    border: 1px solid $white;
    background-color: $grey-light;
    border-radius: 4px;
    outline: none;
    resize: none;
    .PhoneInputCountry {
      justify-content: space-between;
      border-radius: 4px 0 0 4px;
      background-color: rgba(140, 159, 175, 0.15);
      width: 112.01px;
      padding-left: 15px;
      padding-right: 15px;
    }
    input {
      padding: 11px 15px;
      width: 100%;
      color: $dark-grey;
      font-size: 16px;
      border: 1px solid transparent;
      border-radius: 4px;
      background-color: $grey-light;
      outline: none;
      resize: none;

      &::placeholder {
        color: $color-text-grayed-light;
      }
    }
  }
  span {
    position: relative;
    top: -38.5px;
    left: 45px;
    color: $color-text-grayed-light;
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 26px;
  }

  .phone-input:focus-within {
    border-color: $color-text-grayed-light;
    background-color: $grey-light;
    color: $dark-grey;
  }

  .phone-input.error {
    border-color: $color-error;
  }
  .phone-input-message {
    color: $color-error;
    font-size: 0.6875rem;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: -22px;
  }
}

//ie media query
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .phone-input-field {
    span {
      top: -35px;
      left: -130px;
    }
  }
}
