@import '../../styles/_biocant-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';
.slider-2{
  height: 100%;
  .card{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    max-width: 621px;
    min-height: 324px;
    border-radius: 4px;
    border-left: 6px solid $green-dark;
    background-color: $white;
    box-shadow: 0 30px 30px 0 rgba(182,192,247,0.13);
    padding: 55px;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:before{
      position: absolute;
      content: '';
      background: url('../../assets/icons/form.svg') no-repeat center center;
      background-size: contain;
      height: 184px;
      width: 155px;
      bottom:0;
      right:0;
      z-index: -1;
    }
    .description{
      color: rgba(27,33,43,0.8);
      opacity: 0.8;
      line-height: 32px;
      z-index: 1;
      min-height: 256px;
      max-height: 256px;
      overflow: hidden;
      @include line-clamp(8);
      &:after {
        content: "...";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: 1.2em;
      }
    }
    .author{
      font-weight: bold;
      color: $color-text-dark;
      padding-top: 40px;
      display: inline-flex;
      line-height: 20px;
      a{
        text-decoration: none;
        position: relative;
        content: '';
        &:before{
          position: absolute;
          content: '';
          height: 20px;
          width: 20px;
          background: url("../../assets/icons/link.svg") no-repeat center center;
          background-size: contain;
          margin-left: 10px;
        }
      }
    }
  }


  pre, textarea {
    overflow: auto; }

  [hidden], audio:not([controls]), template {
    display: none; }

  details, main, summary {
    display: block; }

  input[type=number] {
    width: auto; }

  input[type=search], input[type=text], input[type=email] {
    -webkit-appearance: none; }

  input[type="*"] {
    -webkit-appearance: none; }

  input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none; }

  progress {
    display: inline-block; }

  small {
    font-size: 100%; }

  textarea {
    resize: vertical; }

  [unselectable] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

  *, ::after, ::before {
    box-sizing: inherit;
    border-style: solid;
    border-width: 0; }

  * {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0; }

  ::after, ::before {
    text-decoration: inherit;
    vertical-align: inherit; }

  :root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    box-sizing: border-box;
    cursor: default;
    font: 16px/1.5 sans-serif;
    text-rendering: optimizeLegibility; }

  a {
    text-decoration: none; }

  audio, canvas, iframe, img, svg, video {
    vertical-align: middle; }

  button, input, select, textarea {
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    min-height: 1.5em; }

  code, kbd, pre, samp {
    font-family: monospace,monospace; }

  nav ol, nav ul {
    list-style: none; }

  ul li {
    list-style: none; }

  select {
    -moz-appearance: none;
    -webkit-appearance: none; }

  select::-ms-expand {
    display: none; }

  select::-ms-value {
    color: currentColor; }

  table {
    border-collapse: collapse;
    border-spacing: 0; }

  ::-moz-selection {
    background-color: #B3D4FC;
    text-shadow: none; }

  ::selection {
    background-color: #B3D4FC;
    text-shadow: none; }

  @media screen {
    [hidden~=screen] {
      display: inherit; }
    [hidden~=screen]:not(:active):not(:focus):not(:target) {
      clip: rect(0 0 0 0) !important;
      position: absolute !important; } }

  /*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  1.0 Font
  2.0 Trasnitions
  3.0 Number of Slides
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  1.0 Font
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  2.0 Transitions
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  1.0 Config
  2.0 Translate
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  1.0 Transition
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  2.0 Translate
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  1.0 Neutral
  2.0 Main Colors
  3.0 Color Pallete
  3.0 Grey Scale
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  1.0 Neutral
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  2.0 Main color
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  3.0 Color Pallete
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  4.0 Grey Scale
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  1.0 HTML Settings
  2.0 General Settings
  3.0 Heading 2
  4.0 Serif
  5.0 Heading 3
  6.0 Text
  7.0 Links
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  1.0 HTML settings
  --------------------------------------------------------------*/
  html {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    -webkit-tap-highwhite-color: transparent;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; }

  /*--------------------------------------------------------------
  2.0 General Settings
  --------------------------------------------------------------*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

  /*--------------------------------------------------------------
  3.0 Heading 2
  --------------------------------------------------------------*/
  .heading-2 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    letter-spacing: 0.3px; }
  .heading-2--white {
    color: #FFF; }

  /*--------------------------------------------------------------
  4.0 Serif
  --------------------------------------------------------------*/
  .serif {
    font-family: "Arnhem";
    font-size: 24px;
    font-weight: 100;
    line-height: 38px;
    color: #3E4954; }
  @media (max-width: 400px) {
    .serif {
      font-size: 20px;
      line-height: 32px; } }

  /*--------------------------------------------------------------
  5.0 Heading-3
  --------------------------------------------------------------*/
  .heading-3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #3E4954;
    letter-spacing: 0.3px; }
  .heading-3 + .heading-3 {
    margin-top: 5px; }
  .heading-3--white {
    color: #FFF; }
  .heading-3--light {
    color: #848995; }

  /*--------------------------------------------------------------
  6.0 Text
  --------------------------------------------------------------*/
  .text {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #848995;
    letter-spacing: 0; }
  @media (max-width: 600px) {
    .text {
      font-size: 16px;
      line-height: 30px; } }
  .text--light-white {
    color: rgba(255, 255, 255, 0.7); }

  /*--------------------------------------------------------------
  7.0 Links
  --------------------------------------------------------------*/
  .link {
    position: relative;
    display: inline-block;
    font-weight: 600;
    color: #5050FF; }
  .link:after {
    position: absolute;
    top: 50%;
    right: -32px;
    display: block;
    width: 24px;
    height: 14px;
    background-size: cover;
    content: '';
    transform: translateY(-50%);
    transition: all 0.2s ease; }
  .link:hover:after {
    right: -48px; }

  .slider-2__item {
    transition: all 0.2s ease; }
  #slide-1:checked ~ .slider-2__holder .slider-2__item--1 {
    position: relative;
    z-index: 2;
    transform: translate(0) scale(1); }
  #slide-2:checked ~ .slider-2__holder .slider-2__item--1 {
    z-index: 1;
    transform: translateX(-100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-2:checked ~ .slider-2__holder .slider-2__item--1 {
      opacity: 0.6; } }
  #slide-3:checked ~ .slider-2__holder .slider-2__item--1 {
    z-index: 0;
    transform: translateX(-210px) scale(0.65); }
  @media (max-width: 900px) {
    #slide-3:checked ~ .slider-2__holder .slider-2__item--1 {
      transform: translateX(-170px) scale(0.65); } }
  @media (max-width: 768px) {
    #slide-3:checked ~ .slider-2__holder .slider-2__item--1 {
      opacity: 0; } }
  #slide-4:checked ~ .slider-2__holder .slider-2__item--1 {
    z-index: -1;
    opacity: 0;
    transform: translateX(-210px) scale(0.65); }
  #slide-5:checked ~ .slider-2__holder .slider-2__item--1 {
    z-index: -1;
    opacity: 0;
    transform: translateX(-210px) scale(0.65); }
  #slide-1:checked ~ .slider-2__holder .slider-2__item--2 {
    z-index: 1;
    transform: translateX(100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-1:checked ~ .slider-2__holder .slider-2__item--2 {
      opacity: 0.6; } }
  #slide-2:checked ~ .slider-2__holder .slider-2__item--2 {
    position: relative;
    z-index: 2;
    transform: translate(0) scale(1); }
  #slide-3:checked ~ .slider-2__holder .slider-2__item--2 {
    z-index: 1;
    transform: translateX(-100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-3:checked ~ .slider-2__holder .slider-2__item--2 {
      opacity: 0.6; } }
  #slide-4:checked ~ .slider-2__holder .slider-2__item--2 {
    z-index: 0;
    transform: translateX(-210px) scale(0.65); }
  @media (max-width: 900px) {
    #slide-4:checked ~ .slider-2__holder .slider-2__item--2 {
      transform: translateX(-170px) scale(0.65); } }
  @media (max-width: 768px) {
    #slide-4:checked ~ .slider-2__holder .slider-2__item--2 {
      opacity: 0; } }
  #slide-5:checked ~ .slider-2__holder .slider-2__item--2 {
    z-index: -1;
    opacity: 0;
    transform: translateX(-210px) scale(0.65); }
  #slide-1:checked ~ .slider-2__holder .slider-2__item--3 {
    z-index: 0;
    transform: translateX(210px) scale(0.65); }
  @media (max-width: 900px) {
    #slide-1:checked ~ .slider-2__holder .slider-2__item--3 {
      transform: translateX(170px) scale(0.65); } }
  @media (max-width: 768px) {
    #slide-1:checked ~ .slider-2__holder .slider-2__item--3 {
      opacity: 0; } }
  #slide-2:checked ~ .slider-2__holder .slider-2__item--3 {
    z-index: 1;
    transform: translateX(100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-2:checked ~ .slider-2__holder .slider-2__item--3 {
      opacity: 0.6; } }
  #slide-3:checked ~ .slider-2__holder .slider-2__item--3 {
    position: relative;
    z-index: 2;
    transform: translate(0) scale(1); }
  #slide-4:checked ~ .slider-2__holder .slider-2__item--3 {
    z-index: 1;
    transform: translateX(-100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-4:checked ~ .slider-2__holder .slider-2__item--3 {
      opacity: 0.6; } }
  #slide-5:checked ~ .slider-2__holder .slider-2__item--3 {
    z-index: 0;
    transform: translateX(-210px) scale(0.65); }
  @media (max-width: 900px) {
    #slide-5:checked ~ .slider-2__holder .slider-2__item--3 {
      transform: translateX(-170px) scale(0.65); } }
  @media (max-width: 768px) {
    #slide-5:checked ~ .slider-2__holder .slider-2__item--3 {
      opacity: 0; } }
  #slide-1:checked ~ .slider-2__holder .slider-2__item--4 {
    z-index: -1;
    opacity: 0;
    transform: translateX(210px) scale(0.65); }
  #slide-2:checked ~ .slider-2__holder .slider-2__item--4 {
    z-index: 0;
    transform: translateX(210px) scale(0.65); }
  @media (max-width: 900px) {
    #slide-2:checked ~ .slider-2__holder .slider-2__item--4 {
      transform: translateX(170px) scale(0.65); } }
  @media (max-width: 768px) {
    #slide-2:checked ~ .slider-2__holder .slider-2__item--4 {
      opacity: 0; } }
  #slide-3:checked ~ .slider-2__holder .slider-2__item--4 {
    z-index: 1;
    transform: translateX(100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-3:checked ~ .slider-2__holder .slider-2__item--4 {
      opacity: 0.6; } }
  #slide-4:checked ~ .slider-2__holder .slider-2__item--4 {
    position: relative;
    z-index: 2;
    transform: translate(0) scale(1); }
  #slide-5:checked ~ .slider-2__holder .slider-2__item--4 {
    z-index: 1;
    transform: translateX(-100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-5:checked ~ .slider-2__holder .slider-2__item--4 {
      opacity: 0.6; } }
  #slide-1:checked ~ .slider-2__holder .slider-2__item--5 {
    z-index: -1;
    opacity: 0;
    transform: translateX(210px) scale(0.65); }
  #slide-2:checked ~ .slider-2__holder .slider-2__item--5 {
    z-index: -1;
    opacity: 0;
    transform: translateX(210px) scale(0.65); }
  #slide-3:checked ~ .slider-2__holder .slider-2__item--5 {
    z-index: 0;
    transform: translateX(210px) scale(0.65); }
  @media (max-width: 900px) {
    #slide-3:checked ~ .slider-2__holder .slider-2__item--5 {
      transform: translateX(170px) scale(0.65); } }
  @media (max-width: 768px) {
    #slide-3:checked ~ .slider-2__holder .slider-2__item--5 {
      opacity: 0; } }
  #slide-4:checked ~ .slider-2__holder .slider-2__item--5 {
    z-index: 1;
    transform: translateX(100px) scale(0.85); }
  @media (max-width: 768px) {
    #slide-4:checked ~ .slider-2__holder .slider-2__item--5 {
      opacity: 0.6; } }
  #slide-5:checked ~ .slider-2__holder .slider-2__item--5 {
    position: relative;
    z-index: 2;
    transform: translate(0) scale(1); }

  /*--------------------------------------------------------------
  3.0 Bullets
  --------------------------------------------------------------*/
  .bullets__item {
    transition: all 0.2s ease; }
  #slide-1:checked ~ .bullets .bullets__item--1 {
    //background: #FFF;
    background: url("../../assets/icons/slider/selected.svg") no-repeat center center;
    background-size: contain;
    height: 18px;
    width: 16px;
}
  #slide-2:checked ~ .bullets .bullets__item--2 {
    background: url("../../assets/icons/slider/selected.svg") no-repeat center center;
    background-size: contain;
    height: 18px;
    width: 16px;
  }
  #slide-3:checked ~ .bullets .bullets__item--3 {
    background: url("../../assets/icons/slider/selected.svg") no-repeat center center;
    background-size: contain;
    height: 18px;
    width: 16px;
  }
  #slide-4:checked ~ .bullets .bullets__item--4 {
    background: url("../../assets/icons/slider/selected.svg") no-repeat center center;
    background-size: contain;
    height: 18px;
    width: 16px;
  }
  #slide-5:checked ~ .bullets .bullets__item--5 {
    background: url("../../assets/icons/slider/selected.svg") no-repeat center center;
    background-size: contain;
    height: 18px;
    width: 16px;
  }

  /*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  1.0 Card
  2.0 slider-2
  3.0 Bullets
  4.0 Section
  5.0 Button
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  1.0 Card
  --------------------------------------------------------------*/

  /*--------------------------------------------------------------
  2.0 slider-2
  --------------------------------------------------------------*/
  .slider-2 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .slider-2__radio {
    display: none; }
  .slider-2__holder {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 80px auto 50px;
    text-align: left; }
  @media (max-width: 900px) {
    .slider-2__holder {
      max-width: 540px; } }
  @media (max-width: 600px) {
    .slider-2__holder {
      margin-top: 60px; } }
  .slider-2__item {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    opacity: 1;
    cursor: pointer; }
  .slider-2__item-content {
    padding: 40px; }
  @media (max-width: 600px) {
    .slider-2__item-content {
      padding: 32px 32px; } }
  @media (max-width: 375px) {
    .slider-2__item-content {
      padding: 40px 24px; } }
  .slider-2__item-text {
    padding: 60px 0; }

  /*--------------------------------------------------------------
  3.0 Bullets
  --------------------------------------------------------------*/
  .bullets {
    z-index: 10;
    display: block;
    width: auto;
    margin: 48px auto 0;
    text-align: center; }
  @media (max-width: 600px) {
    .bullets {
      margin-top: 32px; } }
  .bullets__item {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    border-radius: 6px;
    //background: rgba(0, 0, 0, 0.2);
    background: url("../../assets/icons/slider/oval.svg") no-repeat center center;
    background-size: contain;
    cursor: pointer; }
  .bullets__item:hover {
    background: url("../../assets/icons/slider/oval-green.svg") no-repeat center center;
    background-size: contain;
  }

  /*--------------------------------------------------------------
  4.0 Section
  --------------------------------------------------------------*/
  .section {
    position: relative;
    width: 100%;
    padding: 120px 24px;
    text-align: center; }
  .section__entry {
    width: 100%;
    max-width: 380px;
    margin: 0 auto; }
  .section__entry--center {
    text-align: center; }
  .section__title {
    display: block;
    padding-bottom: 12px; }
  .section__text {
    display: block; }

  /*--------------------------------------------------------------
  5.0 Button
  --------------------------------------------------------------*/
  .button {
    display: inline-block;
    height: 44px;
    padding: 12px 16px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.2); }
  .button:hover {
    background: rgba(0, 0, 0, 0.1); }

  /*--------------------------------------------------------------
  4.0 All Tutorials
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  1.0 Labs bar
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  1.0 Labs bar
  --------------------------------------------------------------*/
  .labs-bar {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    padding: 8px 0;
    background: rgba(0, 0, 0, 0.2); }
  .labs-bar__inner {
    position: relative;
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; }
  .labs-bar__nav {
    position: relative;
    display: block;
    float: left; }
  .labs-bar__link {
    position: relative;
    display: inline-block;
    padding: 10px;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #FFF;
    letter-spacing: 0.5px;
    text-transform: uppercase; }
  .labs-bar__link:hover:before {
    left: -16px; }
  .labs-bar__link:before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 24px;
    height: 14px;
    background-size: cover;
    content: '';
    transform: translateY(-50%);
    transition: all 0.2s ease; }
  .labs-bar__branding {
    display: inline-block;
    font-size: 20px;
    line-height: 20px;
    color: #FFF; }
  .labs-bar__logo {
    width: auto;
    height: 18px;
    margin-top: 5px;
    margin-left: 10px; }
  .labs-bar__logo:hover {
    opacity: 0.8; }
  .labs-bar__button {
    float: right;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #FFF;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;
    text-transform: uppercase; }
  .labs-bar__button:hover {
    background: rgba(0, 0, 0, 0.1); }
}

@media (max-width: $md-size) {
 .slider-2{
   .card{
     padding: 25px 16px;
   }
 }
}
