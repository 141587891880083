
// Font styling
$font-family-base: 'Lato', sans-serif;
$font-family-secondary: 'Cabin', sans-serif;
$font-size-base: 16px;

// Color styling
$color-theme-1: #32C878;
$color-theme-2: #0B562E;
$color-background-light: #F9FAFB;
$color-background-silver-70: rgba(242, 243, 251, 0.7);
$color-background-grayed: #ededf8;
$color-background-grayed-light-45: rgba(234, 237, 243, 0.45);
$color-text-dark: #1B212B;
$color-text-light: #ffffff;
$color-background-blue-light: #f5f6fc;
$color-text-grayed-dark: #484848;
$color-text-grayed: #767676;
$color-text-grayed-light: #8C9FAF;
$color-pagination: #637280;
$color-pagination-light: #ffffff;
$color-text-main: #637280;
$color-text-main-50: rgba(99, 114, 128, 0.5);
$color-error: #F44336;
$color-background-error: #ffeded;
$color-message-error: #A24B6D;
$color-message-background-error: #E7CFD3;
$green-light :#CAFFE3;
$green-dark :#05B76E;
$green-light :#5BD393;
$grey-light: #f8f9fa;
$dark-blue: #000e51;
$black : #000000;
$white : #ffffff;
$dark-grey : #62707C;

// Border styling
$border-radius: 4px;

// Grid sizes
$xs-size: 34em;
$sm-size: 48em;
$md-size: 62em;
$lg-size: 75em;

// Margin
$size-to-menu-desktop: 90px;
$size-to-menu-desktop-small: 95px;
$size-to-menu-mobile: 66px;
$size-to-footer-mobile: 54px;
