@import '../../styles/_biocant-variables';

.step {
  .content {
    text-align: left;
    .title {
      position: relative;
      font-family: $font-family-secondary;
      display: flex;
      color: $dark-blue;
      div {
        display: inline-block;
      }
      margin-bottom: 35px;
      .title-label {
        h4 {
          font-family: $font-family-secondary;
        }
      }
    }
    .description {
      p {
        margin-bottom: 35px;
        opacity: 0.8;
        color: $dark-grey;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}

@media (max-width: $md-size) {
  .step {
    margin-bottom: 85px;
    .content {
      text-align: left;
      .title {
        position: relative;
        div {
          display: inline-block;
        }
        margin-bottom: 29px;
      }
      .description {
        p {
          margin-bottom: 44px;
        }
        a,
        button {
          width: 100%;
        }
      }
    }
  }
}
