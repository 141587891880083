@-webkit-keyframes partnersSliderTimer {
  0% {
    stroke-dashoffset: 119.3808
  }

  to {
    stroke-dashoffset: 0
  }
}

@keyframes partnersSliderTimer {
  0% {
    stroke-dashoffset: 119.3808
  }

  to {
    stroke-dashoffset: 0
  }
}

@-webkit-keyframes partnersSliderTimerRevert {
  0% {
    stroke-dashoffset: 0
  }

  to {
    stroke-dashoffset: -119.3808
  }
}

@keyframes partnersSliderTimerRevert {
  0% {
    stroke-dashoffset: 0
  }

  to {
    stroke-dashoffset: -119.3808
  }
}

@-webkit-keyframes moveImage {
  0% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  25% {
    -webkit-transform: translate3d(-10px, -10px, 25px);
    transform: translate3d(-20px, -20px, 25px); }
  50% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(40px, 40px, 50px); }
  75% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(-20px, -20px, 25px); }
  100% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0px, 0px, 0px); } }
@keyframes moveImage {
  0% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  25% {
    -webkit-transform: translate3d(-10px, -10px, 25px);
    transform: translate3d(-20px, -20px, 25px); }
  50% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(40px, 40px, 50px); }
  75% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(-20px, -20px, 25px); }
  100% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@keyframes openPage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    @include transform(translateX(-75px));
  }

  100% {
    @include transform(translateX(0px));
    opacity: 1;
  }
}

@keyframes fadeInLater {
  0% {
    opacity: 0;
    @include transform(translateX(-20px));
  }

  100% {
    @include transform(translateX(0px));
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}
