@import "../../styles/_biocant-variables.scss";
@import '../../styles/_mixins';

.not-implemented-wrapper {
  .not-implemented-content{
    height: 100%;
    width: 100%;
    background: url("../../assets/images/404/DAN08-201D-6397 2.png") no-repeat center center;
    background-size: cover;
    padding: 200px 0;
  }
  background-color: $green-dark;
  text-align: center;

  img {
    width: 101.44px;
    height: 116.32px;
    margin-bottom: 50px;
  }

  .title{
    color: $green-light;
    font-size: 50px;
    line-height: 63px;
    text-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
    font-weight: bold;
  }

  .message{
    font-size: 20px;
    color: $color-text-light;
    text-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
    line-height: 32px;
  }

  button{
    background-color: $white;
    color: $color-theme-1;
    margin-top: 50px;
  }
}
