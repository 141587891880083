@import '../../styles/_biocant-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';

#page-header {
  h6 {
    color: $white;
    background-color: $color-theme-1;
    padding: 5px 20px;
    border-radius: 3px;
    font-weight: 600;
    line-height: 26px;
    font-family: $font-family-secondary;
  }
}
