@import '../../styles/_biocant-variables';

.info-bubbles {
  padding-bottom: 20px;
  justify-content: center;
  text-align: center;
  .content-col {
    margin: 0 auto;
    padding-bottom: 100px;
    padding-top: 50px;
    .title {
      h4, p {
        color: $dark-blue;
        font-family: $font-family-secondary;
        font-size: 35px;
        line-height: 50px;
      }
      h4 {
        font-weight: bold;
      }
    }
    .description {
      margin-top: 31px;
      margin-bottom: 31px;
      text-align: center;
      p {
        opacity: 0.8;
        color: $dark-grey;
        font-family: $font-family-base;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
  .img-col {
    flex-wrap: wrap;
    div {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 50%;
      -webkit-box-shadow: 0 14px 27px 0 rgba(182, 192, 247, 0.13),
        0 27px 14px 0 rgba(182, 192, 247, 0.13);
      -moz-box-shadow: 0 14px 27px 0 rgba(182, 192, 247, 0.13),
        0 27px 14px 0 rgba(182, 192, 247, 0.13);
      box-shadow: 0 14px 27px 0 rgba(182, 192, 247, 0.13),
        0 27px 14px 0 rgba(182, 192, 247, 0.13);
      img {
        max-width: 100%;
        max-height: 100%;
        padding: 16px;
        object-fit: contain;
      }
    }
  }
  .img-col-left {
    div:nth-child(1) {
      position: absolute;
      margin-left: 160px;
      margin-top: -20px;
      width: 114px;
      height: 114px;
    }
    div:nth-child(2) {
      position: absolute;
      margin-left: 20px;
      margin-top: 30px;
      width: 100px;
      height: 100px;
    }
    div:nth-child(3) {
      position: absolute;
      margin-left: 140px;
      margin-top: 135px;
      width: 124px;
      height: 124px;
    }
    div:nth-child(4) {
      position: absolute;
      margin-left: -30px;
      margin-top: 170px;
      width: 135px;
      height: 135px;
    }
    div:nth-child(5) {
      position: absolute;
      margin-left: 100px;
      margin-top: 285px;
      width: 99.9px;
      height: 99.9px;
    }
  }

  .img-col-right {
    overflow: hidden;
    div:nth-child(1) {
      position: absolute;
      margin-left: 25px;
      margin-top: -10px;
      width: 100px;
      height: 100px;
    }
    div:nth-child(2) {
      position: absolute;
      margin-left: 185px;
      margin-top: -15px;
      width: 124px;
      height: 124px;
    }
    div:nth-child(3) {
      position: absolute;
      margin-left: -10px;
      margin-top: 120px;
      width: 127px;
      height: 127px;
    }
    div:nth-child(4) {
      position: absolute;
      margin-left: 150px;
      margin-top: 125px;
      width: 99.9px;
      height: 99.9px;
    }
    div:nth-child(5) {
      position: absolute;
      margin-left: 200px;
      margin-top: 250px;
      width: 98px;
      height: 98px;
    }
  }
}

@media (max-width: $md-size) {
  .info-bubbles {
    padding-top: 159px;
    padding-bottom: 100px;
    .content-col {
      padding-left: 21px;
      padding-right: 21px;
      padding-bottom: 90px;
      a,
      button {
        width: 85%;
      }
      .description {
        text-align: center;
      }
    }
    .img-col-left {
      flex-basis: auto;
      div:nth-child(1) {
        position: absolute;
        margin-left: -70px;
        margin-top: -100px;
        width: 84px;
        height: 84px;
      }
      div:nth-child(2) {
        position: absolute;
        margin-left: -170px;
        margin-top: -75px;
        width: 92px;
        height: 92px;
      }
      div:nth-child(3) {
        position: absolute;
        margin-left: 20px;
        margin-top: -26.6px;
        width: 70px;
        height: 70px;
      }
      div:nth-child(4) {
        position: absolute;
        margin-left: 70px;
        margin-top: -110px;
        width: 100px;
        height: 100px;
      }
      div:nth-child(5) {
        display: none;
      }
    }

    .img-col-right {
      flex-basis: auto;
      div:nth-child(1) {
        position: absolute;
        margin-left: -170px;
        margin-top: -75px;
        width: 84px;
        height: 84px;
      }
      div:nth-child(2) {
        position: absolute;
        margin-left: -95px;
        margin-top: 0px;
        width: 68px;
        height: 68px;
      }
      div:nth-child(3) {
        position: absolute;
        margin-left: 0px;
        margin-top: -60px;
        width: 77px;
        height: 77px;
      }
      div:nth-child(4) {
        position: absolute;
        margin-left: 90px;
        margin-top: -20px;
        width: 83px;
        height: 83px;
      }
      div:nth-child(5) {
        display: none;
      }
    }
  }
}

@media (min-width: $md-size) {
  .info-bubbles {
    .img-col-left {
      margin-left: -90px;
    }
    .img-col-right {
      margin-right: -90px;
    }
  }
}
