@import "../../styles/_biocant-variables.scss";
.error-message{
  width: 100%;
  background-color: $color-message-background-error;
  color: $color-message-error;
  text-align: left;
  padding: 13px 21px;
  margin-top: 16px;
  border-radius: 4px;
  p{
	line-height: 20px;
	font-weight: bold;
	font-size: 15px;
	font-family: $font-family-base;
  }
}
