@import '../../styles/_biocant-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';

#home-page {
  & > .slider {
    height: 100%;
    position: relative;
    .container {
      padding-top: 20px;
    }
    .slide {
      height: 628px;
      background: no-repeat center center;
      background-size: cover;
      position: relative;
      .opacity {
        background-color: rgba(27, 33, 43, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      .content {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        left: 5%;
        max-width: 493px;
        min-width: 288px;
        z-index: 3;
        opacity: 1;
      }
      h1 {
        color: white;
        line-height: 70px;
        margin-bottom: 20px;
        font-weight: 600;
        font-family: $font-family-secondary;
      }
      &.noImageSlide {
        background: url('../../assets/images/home/no-image-news.png') center
          center no-repeat;
      }
    }
    .slider-control-centerright {
      top: 45% !important;
      right: 10px !important;
      left: 90% !important;
    }
    .slider-control-centerleft {
      top: 55% !important;
      right: 10px !important;
      left: 90% !important;
    }
    .slider-control-centerleft,
    .slider-control-centerright {
      button {
        &:hover {
          cursor: pointer;
        }
        &.right-arrow {
          background: url('../../assets/icons/slider/arrow_right.svg') no-repeat
            center !important;
          background-size: cover;
          height: 42px;
          width: 42px;
        }
        &.left-arrow {
          background: url('../../assets/icons/slider/arrow_left.svg') no-repeat
            center !important;
          background-size: cover;
          height: 42px;
          width: 42px;
        }
        position: relative;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        .circle-timer {
          position: absolute;
          top: calc(50% - 21px);
          left: calc(50% - 21px);
          z-index: 2;
          fill: rgba(30, 30, 30, 0);
          stroke: $green-dark;
          stroke-width: 2px;
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          stroke-dasharray: 119.3808;
          stroke-dashoffset: -119.3808;
          -webkit-animation: partnersSliderTimer 8s linear infinite;
          animation: partnersSliderTimer 8s linear infinite;
          -webkit-transition: opacity 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition: opacity 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
        &.reset {
          .circle-timer {
            -webkit-animation: partnersSliderTimerRevert 1s linear forwards;
            animation: partnersSliderTimerRevert 1s linear forwards;
            -webkit-transition: opacity 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: opacity 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
        }
      }
    }
    .slider-control-bottomcenter {
      background-color: $white;
      padding: 10px 10px 0 10px;
      border-radius: 19px 19px 0 0;
      position: relative;
      min-height: 39px;
      max-height: 39px;
      ul {
        li {
          button {
            visibility: hidden;
            opacity: 1 !important;
            &:after {
              content: '';
              visibility: visible;
              display: block;
              position: absolute;
              background: url('../../assets/icons/slider/oval.svg') no-repeat
                center center;
              background-size: cover;
              height: 10px;
              width: 10px;
              top: 17px;
            }
            &.active:after {
              background: url('../../assets/icons/slider/selected.svg')
                no-repeat center center;
              background-size: cover;
              height: 13px;
              width: 13px;
            }
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -0.5px;
        left: -14px;
        background: url('../../assets/icons/slider/shape-left.svg') no-repeat
          center center;
        background-size: cover;
        height: 18px;
        width: 15px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -0.5px;
        right: -14px;
        background: url('../../assets/icons/slider/shape-right.svg') no-repeat
          center center;
        background-size: cover;
        height: 18px;
        width: 15px;
      }
    }
    .slider-frame {
      border-radius: 10px;
    }
    .bullets {
      ul {
        display: inline-flex;
        li {
          padding-left: 10px;
          padding-right: 10px;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            background: url('../../assets/icons/slider/oval.svg') no-repeat
              center center;
            background-size: contain;
            height: 10px;
            width: 10px;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
          &.active {
            &:before {
              background: url('../../assets/icons/slider/selected.svg')
                no-repeat center center;
              background-size: contain;
              height: 16px;
              width: 18px;
              margin-top: -3px;
              top: 67%;
              left: 50%;
              @include transform(translate(-50%, -50%));
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .icons {
      display: inline-flex;
      position: absolute;
      right: 45px;
      top: 45px;
      .icon {
        height: 98px;
        width: 98px;
        &:not(.first) {
          margin-right: -14px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .slider-section {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      background: url('../../assets/icons/polygon-grey.svg') no-repeat center
        center;
      background-size: contain;
      height: 1710px;
      width: 1531px;
      right: -1247px;
      top: 180px;
    }
  }
  .description {
    position: relative;
    font: $font-family-base;
    .icon {
      img {
        height: 29px;
        width: 25px;
        z-index: 1;
      }
    }
    .title {
      margin-top: 10px;
      color: $dark-blue;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      .bold {
        font-weight: 700;
      }
    }
  }
  .steps {
    position: relative;
    margin-top: 65px;
    .step + .step {
      margin-top: 67px;
    }
    .row .image img {
      max-width: 486px;
      width: 100%;
      height: 100%;
    }
  }
  .our-companies {
    margin-top: 128px
  }
  .testimonials {
    .row {
      align-content: center;
      justify-content: flex-end;
      width: 100%;
      .quote {
        padding: 20px 0;
        h4 {
          color: $dark-blue;
          font-family: $font-family-secondary;
          font-size: 35px;
          font-weight: bold;
          line-height: 50px;
        }
      }
      .author {
        padding-top: 5px;
        h5 {
          opacity: 0.8;
          color: $dark-grey;
          font-family: $font-family-base;
          font-size: 24px;
          font-weight: bold;
        }
      }
      .role {
        padding-top: 5px;
        h6 {
          opacity: 0.8;
          color: $dark-grey;
          font-family: $font-family-base;
          font-size: 20px;
          line-height: 18px;
        }
      }
      .video-image {
        position: relative;
        &:before {
          content: '';
          height: 695px;
          width: 672px;
          background: url('../../assets/images/home/testimonials.png') no-repeat
            center center;
          background-size: contain;
          position: absolute;
          top: 0;
          right: -190px;
        }
        .play-button {
          position: absolute;
          background: url('../../assets/images/park/play.svg') no-repeat center
            center;
          background-size: cover;
          height: 98px;
          width: 98px;
          z-index: 10;
          top: 335px;
          right: 50px;
        }
      }
    }
  }

  .people-say {
    margin-top: 90px;
    .people-say-container {
      padding-bottom: 90px;
      background-color: $grey-light;
    }
    .title {
      padding: 75px 0 0 0;
      color: $color-text-grayed-light;
    }
    h4 {
      font-family: $font-family-secondary;
      span {
        font-family: $font-family-secondary;
      }
    }
    .date {
      color: $color-text-grayed-light;
      font-size: 14px;
      font-family: $font-family-base;
      line-height: 19px;
    }
  }
  .question {
    padding: 100px 0;
    .title {
      h2 {
        font-weight: bold;
        line-height: 63px;
        font-family: $font-family-secondary;
        color: $color-text-dark;
      }
      h6 {
        padding: 45px 0;
        color: $color-text-grayed-light;
        span {
          display: inline-block;
        }
      }
    }
    .success {
      transition: opacity;
      padding: 210px 0;
      img {
        padding-bottom: 60px;
      }
      h3 {
        font-weight: bold;
        padding-bottom: 20px;
      }
      h6 {
        color: $color-text-grayed-light;
        opacity: 0.8;
      }
    }
    .btn {
      border: none;
    }
    input,
    textarea {
      color: $color-text-grayed-light;
      font-family: $font-family-base;
      font-size: 16px;
      line-height: 26px;
    }
  }
}

@media (min-width: $md-size) {
  #home-page {
    & > .slider {
      height: 628px;
      margin-bottom: 120px;
      .slider {
        background-color: $grey-light;
      }
      .slider-slide {
        .button {
          opacity: 0;
        }
        .title {
          opacity: 0;
        }
        &.slide-visible {
          .title {
            overflow-y: hidden;
            max-height: 431px;
            -webkit-animation: fadeIn 1s forwards;
            -webkit-animation-delay: 1s;
            animation: fadeIn 1s forwards;
            animation-delay: 0.5s;
          }
          .button {
            -webkit-animation: fadeInLater 1.5s forwards;
            -webkit-animation-delay: 2s;
            animation: fadeInLater 1.5s forwards;
            animation-delay: 1s;
          }
        }
      }
    }
    .testimonials {
      overflow-x: hidden;
      .video-image {
        height: 695px;
      }
    }
    .video-image {
      .play-button {
        transform: scale(1);
        transition: padding 0.3s ease 0.6s, transform 0.3s ease;
        &:hover {
          cursor: pointer;
          transform: scale(1.3);
        }
      }
    }
    .icons-mobile {
      display: none;
    }
    .steps {
      .content {
        margin-left: 30px;
      }
    }
  }
}

@media (max-width: $md-size) {
  #home-page {
    & > .slider {
      margin-bottom: 55px;
      height: 87vh;
      .slider {
        height: 87vh !important;
        .slider-slide {
          height: 87vh !important;
          .slide {
            height: 87vh !important;
          }
        }
      }
      .container {
        padding-top: 6px;
        height: 520px;
        .slider-section {
          //height: 75%;
          height: 520px;
          .slider-frame {
            height: 520px;
          }
          .slider-list {
            height: 100%;
          }
        }
      }
      .slide {
        .content {
          width: 100%;
          left: 0;
          max-width: none;
          height: 100%;
          .title {
            width: 100%;
            height: 70%;
            position: relative;
            overflow: hidden;
          }
          .button {
            width: 100%;
            height: 30%;
          }
          h1 {
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%, -50%));
            line-height: 49px;
            text-shadow: 0 10px 20px 0 rgba(27, 33, 43, 0.05);
            font-size: 38px;
            max-height: 300px;
            overflow: hidden;
          }
          a,
          button {
            width: 30%;
          }
        }
      }
      .icons {
        display: none;
      }
    }
    .icons-mobile {
      margin-bottom: 50px;
      .icons {
        display: inline-flex;
      }
    }
    .slider-section {
      &:before {
        display: none;
      }
    }
    .steps {
      .step {
        margin-top: 40px;
      }
      .content {
        text-align: left;
        margin-top: 62px;
        .title {
          position: relative;
          div {
            display: inline-block;
          }
          margin-bottom: 27px;
        }
        .description {
          p {
            margin-bottom: 35px;
          }
          a,
          button {
            width: 100%;
          }
        }
      }
    }
    .slider-control-centerright,
    .slider-control-centerleft {
      display: none;
    }
    .slider-control-bottomcenter {
      bottom: -1px !important;
    }
    .our-companies {
      margin-top: 0;
      .info-bubbles {
        padding-top: 90px;
        .title h4 {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
    .testimonials {
      .row {
        padding-top: 20px;
        width: unset;
        .quote h4 {
          font-size: 30px;
          line-height: 45px;
        }
        .video-image {
          position: relative;
          &:before {
            content: '';
            height: 450px;
            background: url('../../assets/images/home/testimonials.png')
              no-repeat center center;
            background-size: contain;
            position: relative;
            top: -10px;
            right: -40px;
          }
          .play-button {
            position: absolute;
            background: url('../../assets/images/park/play.svg') no-repeat
              center center;
            background-size: cover;
            height: 59px;
            width: 59px;
            z-index: 10;
            top: 200px;
            right: 90px;
          }
        }
      }
    }
    .people-say {
      margin-top: 37px;
      padding-bottom: 0;
      .people-say-container {
        background-color: unset;
        padding: 0;
        .title {
          padding: 0;
        }
      }
      .slider {
        height: 400px;
        .slider-2__holder {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: $sm-size) {
  #home-page {
    .slider {
      .slide {
        .content {
          a,
          button {
            width: 85%;
          }
          .title {
            h1 {
              font-size: 26px;
              line-height: 35px;
              width: 90%;
            }
          }
        }
      }
    }
    .question {
      .title {
        h6 span {
          display: inline;
        }
        h6 span + span:before {
          content: ' ';
        }
      }
    }
  }
}

/* ipads, tablets */
@media (min-width: 768px) and (max-width: 991px) {
  #home-page {
    .testimonials {
      .row {
        .video-image {
          .play-button {
            top: 50%;
            right: 40%;
          }
        }
      }
    }
  }
}
